<template>
  <div>
    <button @click="subscribePush3">Enable Push Notifications</button>
    <iframe src="https://bo.developpeur-full-stack.fr" width="100%" height="400"></iframe>
  </div>
  <div id="news"></div>
</template>

<script>
/* eslint-disable */
export default { 
  methods: {
    async subscribePush3() {
      
      const registration = await navigator.serviceWorker.register('service.js')
      const permissionResult = await Notification.requestPermission()
      
      if (permissionResult !== 'granted') {
        return
      }   

      console.log('Push Notif granted')
      const pushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BAvRUfHZYin135REk2uBhbbqo6B6TTTDe8eZTdeaUeeXVUk_cuBle5RnQDo1iHSFgVWMBBAmQmAcKByCwPqP9Bk'
      })

      console.log('pushSubscription', JSON.stringify(pushSubscription))

      await fetch('https://push.touscommerces.fr/subscribe', {
        method: 'POST',
        body: JSON.stringify(pushSubscription),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    async Message() {
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Message from service worker:', event.data);
        document.getElementById('news').textContent = event.data.message;
      });
    }
  }
}
</script>